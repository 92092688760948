#root,
body {
  height: 100vh;
}

.app {
  margin: 0 3rem;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-bottom: 0;
}

.main {
  flex-grow: 1;
}

.flex {
  display: flex;
  width: 100%;
}
.split > div {
  flex-grow: 1;
  max-width: 50%;
}

.url > input {
  margin-right: 1rem;
  flex-grow: 1;
}

button {
  width: 10rem;
}

.example {
  margin-right: 2rem;
}

.response {
  background-color: black;
  color: white;
  padding: 2rem;
  margin-right: 2rem;
  border-radius: 0.2rem;

  white-space: pre-wrap;
  font-family: monospace;
}

.class {
  margin-bottom: 2rem;
}

.inline > * {
  display: inline;
}
.inline > p {
  color: grey;
  margin-left: 1rem;
}
